import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GqlInput } from '@rcg/core/models';
import { DataCounterDisplayType } from '../../../side-nav/models/nav-menu-item.model';
import { DataCounterService } from '../../services/data-counter.service';

@Component({
  selector: 'rcg-data-counter',
  templateUrl: './data-counter.component.html',
  styleUrls: ['./data-counter.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DataCounterService],
  imports: [CommonModule, MatIconModule, MatTooltipModule, MatBadgeModule],
})
export class DataCounterComponent implements OnInit {
  @Input() gqlInput!: GqlInput;

  @Input() displayType: DataCounterDisplayType = 'badge';

  @Input() badgeIcon?: string | null | undefined;

  @Input() textPrefix?: string | null | undefined;

  @Input() textSuffix?: string | null | undefined;

  @Input() showZeroCount = true;

  constructor(public dataCounterService: DataCounterService) { }

  ngOnInit(): void {
    if (this.gqlInput) {
      this.dataCounterService.subscribeToCountData(this.gqlInput);
    }
  }
}
