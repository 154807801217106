import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, EventType, NavigationEnd, Params, Router, RouterModule } from '@angular/router';
import { RcgPipesModule } from '@rcg/core/pipes';
import { TenantId } from '@rcg/core/utils/tenant-ids';
import { IntlModule } from '@rcg/intl';
import { RcgCardComponent } from '@rcg/shared/components';
import { AvatarModule } from '@rcg/shared/modules/avatar';
import { DynamicDialogService, PriorityIndicatorComponent, StatusColorComponent } from '@rcg/standalone/components';
import { Subscription, filter, map, startWith } from 'rxjs';
import { ProjectComponent } from '../project/project.component';

const multiselectPathSeparator = '&';

export type DoubleClickDialogType = 'window' | 'dialog';

@Component({
  selector: 'rcg-helpdesk-item',
  templateUrl: './helpdesk-item.component.html',
  styleUrls: ['./helpdesk-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    StatusColorComponent,
    RcgPipesModule,
    AvatarModule,
    MatTooltipModule,
    ProjectComponent,
    PriorityIndicatorComponent,
    MatCardModule,
    RouterModule,
    IntlModule,
    RcgCardComponent,
    MatIconModule,
  ],
})
export class HelpdeskItemComponent implements OnChanges, OnDestroy {
  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly changeRef = inject(ChangeDetectorRef);
  private readonly dynamicDialogService = inject(DynamicDialogService);

  private routerEventsSubscription?: Subscription;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() data: any;
  @Input() tenantId!: number;
  @Input() category: string | undefined;
  @Input() height?: number;
  @Input() queryParams?: Params;
  @Input() openOnDoubleClick?: DoubleClickDialogType = 'window';

  @Output() openDialog = new EventEmitter<void>();

  tenant = TenantId;

  active = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (!('queryParams' in changes) && !('data' in changes)) return;

    this.routerEventsSubscription?.unsubscribe();
    this.routerEventsSubscription = this.router.events
      .pipe(
        filter((e) => e.type === EventType.NavigationEnd),
        map((e) => (e as NavigationEnd).urlAfterRedirects),
        startWith(this.router.url),
      )
      .subscribe((url) => this._updateActive(url));
  }

  ngOnDestroy(): void {
    this.routerEventsSubscription?.unsubscribe();
  }

  private _disassembleUrl(url: string) {
    const u = new URL(url, window.location.origin) as URL & { pathSegments: string[] };
    u.pathSegments = u.pathname.split('/');
    return u;
  }

  private _getIds(pathSegments: string[]) {
    return pathSegments[pathSegments.length - 1].split(multiselectPathSeparator).filter((i) => !isNaN(parseInt(i)));
  }

  private _updateActive(url: string) {
    const id = this.data?.id;

    if (!id) {
      this.active = false;
      this.changeRef.markForCheck();
      return;
    }

    const activeUrl = this._disassembleUrl(url);
    const ids = this._getIds(activeUrl.pathSegments);

    this.active = ids.includes(`${id}`);
    this.changeRef.markForCheck();
  }

  async itemClick(event: MouseEvent) {
    if (this.category === 'kanban') {
      return;
    }

    const url = this._disassembleUrl(this.router.url);
    const ids = this._getIds(url.pathSegments);

    if (event.ctrlKey && ids.length) {
      const basePathSegments = url.pathSegments;
      basePathSegments.pop();

      const existingIdIndex = ids.indexOf(`${this.data?.id}`);

      if (existingIdIndex === -1) ids.push(this.data?.id);
      else ids.splice(existingIdIndex, 1);

      await this.router.navigate([...basePathSegments, ids.join(multiselectPathSeparator)], {
        relativeTo: this.activatedRoute,
        queryParams: this.queryParams,
      });

      return;
    }

    const id = this.data?.id;
    if (!id) return;

    await this.router.navigate([`${id}`], {
      relativeTo: this.activatedRoute,
      queryParams: this.queryParams,
    });
  }

  async openDetailWindow(id: number) {
    if (this.openOnDoubleClick === 'window') {
      const origin = this._disassembleUrl(this.router.url).origin;

      window.open(`${origin}/detail/${id}?category=${this.category}&tenant=${this.tenantId}`, `HELPDESK_DETAIL_${id}`, 'popup');
    } else if (this.openOnDoubleClick === 'dialog') {
      this.dynamicDialogService.openDialog({
        width: '0',
        height: '0',
        minWidth: '50vw',
        minHeight: '70vh',
        data: {
          component: 'servicedesk-detail',
          id: id,
          title: this.data?.contact?.full_name ?? '',
          icon: 'chat',
        },
      });
    }
  }
}
