import { Injectable } from '@angular/core';
import { DocumentNode } from '@apollo/client/core';
import * as Get from '@npm-libs/ng-getx';
import { GqlInput, GqlQueryType, normalizeGqlInputQuery } from '@rcg/core/models';
import { GqlResolverService } from '@rcg/core/services';
import { GraphqlClientService } from '@rcg/graphql';
import { Observable, catchError, filter, from, map, of, switchMap } from 'rxjs';

@Get.NgAutoDispose
@Injectable()
export class DataCounterService {
  readonly dataCountR = new Get.Rx<{ value: number | undefined }>({ value: undefined });
  readonly gqlInputR = new Get.Rx<GqlInput | null>(null);

  constructor(private graphQlClient: GraphqlClientService, private gqlResolver: GqlResolverService) {
    this.dataCountR.subscribeTo(
      this.gqlInputR.value$.pipe(
        filter((g) => !!g?.query),
        switchMap((g) => from(this.gqlResolver.resolveGqlInputVariables(g!))),
        switchMap((gqlInput) => {
          return this.countData(normalizeGqlInputQuery(gqlInput.query), gqlInput.type, gqlInput?.variables, gqlInput?.responsePath);
        }),
        map((v) => {
          return { value: v };
        }),
        catchError((err) => {
          console.error('Error data counter - count data', err);
          return of({ value: undefined });
        }),
      ),
    );
  }

  subscribeToCountData(gql: GqlInput) {
    this.gqlInputR.data = gql;
  }

  private countData(
    gqlQuery: DocumentNode,
    gqlType: GqlQueryType,
    variables: Record<string, unknown> | null | undefined,
    responsePath: string | undefined | null,
  ): Observable<number> {
    if (!gqlQuery || !gqlType) return of(0);

    // console.log('Data counter variables:', gqlType, variables);

    if (gqlType === 'query') {
      return this.graphQlClient
        .query<{ data?: unknown }>({
          query: gqlQuery,
          variables: variables ?? {},
        })
        .pipe(
          map((result) => {
            if (!result?.data) return 0;
            const r = responsePath ? this.gqlResolver.resolveGqlResponse(result.data, responsePath) : result!.data;
            return Array.isArray(r) ? r.length : r;
          }),
        );
    }

    return this.graphQlClient
      .subscribe<{ data?: unknown }>({
        query: gqlQuery,
        variables: variables ?? {},
      })
      .pipe(
        map((result) => {
          if (!result?.data) return 0;
          const r = responsePath ? this.gqlResolver.resolveGqlResponse(result.data, responsePath) : result!.data;
          return Array.isArray(r) ? r.length : r;
        }),
      );
  }
}
