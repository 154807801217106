import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { Favorite, FavoritesMenuItemComponent } from '@rcg/filters';
import { IntlModule } from '@rcg/intl';
import { DataCounterComponent } from '../../data-counter/containers/data-counter/data-counter.component';
import { FavoritesMenuPosition, NavMenuItem } from '../models/nav-menu-item.model';
import { CategoryTranslationPipe } from './category-translation.pipe';

@Component({
  selector: 'rcg-nav-menu',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    MatExpansionModule,
    RouterModule,
    DataCounterComponent,
    MatTooltipModule,
    RouterModule,
    IntlModule,
    FavoritesMenuItemComponent,
    CategoryTranslationPipe,
  ],
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavMenuComponent {
  @Input() navMenuItems: NavMenuItem[] = [];

  @Input() favorites?: Favorite[] = [];

  @Input() routable = true;

  @Input() menuItemsExpanded = true;

  @Input() showZeroCount = true;

  @Input() level = 1;

  @Input() isExpanded = false;

  @Input() favoritesMenuPosition?: FavoritesMenuPosition = 'top';

  @Output() navMenuItemClicked = new EventEmitter<NavMenuItem>();

  @Output() selectedFavorite = new EventEmitter<Favorite>();

  onNavMenuItemClicked(navMenuItem: NavMenuItem): void {
    this.navMenuItemClicked.emit(navMenuItem);
  }
}
